import { MetricSpotRoleAggregationDescriptorInput, MetricSpotAggregator } from '@/types/iot-portal';
import { Moment, unitOfTime, duration } from 'moment';

export interface SpotRoleAggregationCounterConfig {
  name: string;
  referenceDate: (now: Moment) => Moment;
  currentDescriptor: (role: string, referenceDate: Moment) => MetricSpotRoleAggregationDescriptorInput;
  comparismDescriptor: (role: string, referenceDate: Moment) => MetricSpotRoleAggregationDescriptorInput | undefined;
  label: (referenceDate: Moment) => string;
  counterProps?: Record<string, unknown>;
}

export const counterConfigCurrent = (
  name: string,
  counterProps?: Record<string, unknown>,
): SpotRoleAggregationCounterConfig => ({
  name,
  referenceDate: (now) => now,
  currentDescriptor: (role, referenceDate) => ({
    role,
    name,
    reference: `${name}_CURRENT`,
    aggregator: MetricSpotAggregator.SUM,
    start: '-P30D',
    stop: referenceDate.toISOString(),
  }),
  comparismDescriptor: () => undefined,
  label: () => 'Aktuell',
  counterProps,
});
export const counterConfigLastMonth = createCounterConfigFactory('LAST_MONTH', 'month', 'P1M', 'MMM YYYY');
export const counterConfigLastYear = createCounterConfigFactory('LAST_YEAR', 'year', 'P1Y', 'YYYY');

function createCounterConfigFactory(
  reference: string,
  startOfUnit: unitOfTime.StartOf,
  intervalLength: string,
  format: string,
): (name: string, counterProps?: Record<string, unknown>) => SpotRoleAggregationCounterConfig {
  const interval = duration(intervalLength);

  return (name, counterProps) => ({
    name,
    referenceDate: (now) => now.clone().startOf(startOfUnit).subtract(interval),
    currentDescriptor: (role, referenceDate) => ({
      role,
      name,
      reference: `${name}_${reference}_current`,
      aggregator: MetricSpotAggregator.SUM,
      start: referenceDate.toISOString(),
      stop: referenceDate.clone().add(interval).toISOString(),
    }),
    comparismDescriptor: (role, referenceDate) => ({
      role,
      name,
      reference: `${name}_${reference}_comparism`,
      aggregator: MetricSpotAggregator.SUM,
      start: referenceDate.clone().subtract(interval).toISOString(),
      stop: referenceDate.toISOString(),
    }),
    label: (referenceDate) => referenceDate.format(format),
    counterProps,
  });
}
